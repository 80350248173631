import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import Dropdown from '@emcasa/ui-dom/components/Dropdown';
import View from '@emcasa/ui-dom/components/View';
import Row from '@emcasa/ui-dom/components/Row';
import Text from '@emcasa/ui-dom/components/Text';
import Icon from '@emcasa/ui-dom/components/Icon';
import ItunesSearch from './ItunesSearch';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "dropdown"
    }}>{`Dropdown`}</h1>
    <p>{`Dropdown component.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Dropdown onChange={value => console.log('selected', value)} mdxType="Dropdown">
  <Dropdown.Option value="a">Option A</Dropdown.Option>
  <Dropdown.Option value="b">Option B</Dropdown.Option>
  <Dropdown.Option value="c">Option C</Dropdown.Option>
    </Dropdown>
    <h2 {...{
      "id": "placeholder-and-labels"
    }}>{`Placeholder and labels`}</h2>
    <View mb="10px" mdxType="View">
  <Text mdxType="Text">Dropdown with placeholder</Text>
  <Dropdown placeholder="Placeholder" mdxType="Dropdown">
    <Dropdown.Option value="a">Option A</Dropdown.Option>
    <Dropdown.Option value="b">Option B</Dropdown.Option>
    <Dropdown.Option value="c" label="Label for option C">Option C</Dropdown.Option>
  </Dropdown>
    </View>
    <View mb="10px" mdxType="View">
  <Text mdxType="Text">Dropdown with fixed label</Text>
  <Dropdown label="Fixed label" mdxType="Dropdown">
    <Dropdown.Option value="a">Option A</Dropdown.Option>
    <Dropdown.Option value="b">Option B</Dropdown.Option>
    <Dropdown.Option value="c">Option C</Dropdown.Option>
  </Dropdown>
    </View>
    <h2 {...{
      "id": "icons"
    }}>{`Icons`}</h2>
    <p>{`You can use the `}<inlineCode parentName="p">{`icon`}</inlineCode>{` and `}<inlineCode parentName="p">{`iconProps`}</inlineCode>{` props on the `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` component to style the button icon.`}</p>
    <Dropdown icon="heart" iconProps={{
      color: 'red'
    }} placeholder="What's your favorite animal?" mdxType="Dropdown">
  <Dropdown.Option value="cat" label="Cat">
    <Icon name="cat" color="dark" mr="15px" mdxType="Icon" />
    <Text inline mdxType="Text">Cat</Text>
  </Dropdown.Option>
  <Dropdown.Option value="dog" label="Dog">
    <Icon name="dog" color="dark" mr="15px" mdxType="Icon" />
    <Text inline mdxType="Text">Dog</Text>
  </Dropdown.Option>
  <Dropdown.Option value="kiwi" label="Kiwi">
    <Icon name="kiwi-bird" color="dark" mr="15px" mdxType="Icon" />
    <Text inline mdxType="Text">Kiwi</Text>
  </Dropdown.Option>
  <Dropdown.Option value="dragon" label="Dragon">
    <Icon name="dragon" color="dark" mr="15px" mdxType="Icon" />
    <Text inline mdxType="Text">Dragon</Text>
  </Dropdown.Option>
    </Dropdown>
    <p>{`Use `}<inlineCode parentName="p">{`flexDirection`}</inlineCode>{` to position the dropdown icon and `}<inlineCode parentName="p">{`textAlign`}</inlineCode>{` to position the dropdown text.`}</p>
    <View mb="10px" mdxType="View">
  <Dropdown flexDirection="row-reverse" placeholder="Select an option" mdxType="Dropdown">
    <Dropdown.Option value="a">Option A</Dropdown.Option>
    <Dropdown.Option value="b">Option B</Dropdown.Option>
    <Dropdown.Option value="c">Option C</Dropdown.Option>
  </Dropdown>
    </View>
    <View mb="10px" mdxType="View">
  <Dropdown flexDirection="row-reverse" textAlign="right" placeholder="Select an option" mdxType="Dropdown">
    <Dropdown.Option value="a">Option A</Dropdown.Option>
    <Dropdown.Option value="b">Option B</Dropdown.Option>
    <Dropdown.Option value="c">Option C</Dropdown.Option>
  </Dropdown>
    </View>
    <h2 {...{
      "id": "advanced-usage"
    }}>{`Advanced usage`}</h2>
    <ItunesSearch mdxType="ItunesSearch">
  {({
        search,
        results,
        onChange
      }) => <Dropdown icon="search" height="tall" containerProps={{
        maxHeight: "150px"
      }} label={<ItunesSearch.Input value={search} onChange={e => onChange(e.target.value)} />} mdxType="Dropdown">
      {results.length ? results.map(({
          collectionId,
          artworkUrl60,
          artistName,
          collectionName
        }) => <Dropdown.Option key={collectionId} value={collectionId}>
            <View mr="15px" mdxType="View">
              <img src={artworkUrl60} height="30px" width="30px" />
            </View>
            {artistName} - {collectionName}
          </Dropdown.Option>) : search.length ? <View mr="15px" ml="15px" mdxType="View">
          <Text mdxType="Text">No results for "{search}"</Text>
        </View> : <View mr="15px" ml="15px" mdxType="View">
          <Text mdxType="Text">Start typing to search albums</Text>
        </View>}
    </Dropdown>}
    </ItunesSearch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      